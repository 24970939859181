define("@ember-data/private-build-infra/current-deprecations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * ## Deprecations
   *
   * EmberData allows users to opt-in and remove code that exists to support deprecated
   * behaviors.
   *
   * If your app has resolved all deprecations present in a given version,
   * you may specify that version as your "compatibility" version to remove
   * the code that supported the deprecated behavior from your app.
   *
   * For instance, if a deprecation was introduced in 3.13, and the app specifies
   * 3.13 as its minimum version compatibility, any deprecations introduced before
   * or during 3.13 would be stripped away.
   *
   * An app can use a different version than what it specifies as it's compatibility
   * version. For instance, an App could be using `3.16` while specifying compatibility
   * with `3.12`. This would remove any deprecations that were present in or before `3.12`
   * but keep support for anything deprecated in or above `3.13`.
   *
   * ### Configuring Compatibility
   *
   * To configure your compatibility version, set the `compatWith` to the version you
   * are compatible with on the `emberData` config in your `ember-cli-build.js` file.
   *
   * ```js
   * let app = new EmberApp(defaults, {
   *   emberData: {
   *     compatWith: '3.12',
   *   },
   * });
   * ```
   *
   * The complete list of which versions specific deprecations will be removed in
   * can be found [here](https://github.com/emberjs/data/tree/master/packages/private-build-infra/addon/current-deprecations.ts "List of EmberData Deprecations")
   *
   * @module @ember-data/deprecations
   * @main @ember-data/deprecations
   */
  var _default = {
    DEPRECATE_CATCH_ALL: '99.0',
    DEPRECATE_3_12: '3.12',
    DEPRECATE_SAVE_PROMISE_ACCESS: '4.4'
  };
  _exports.default = _default;
});